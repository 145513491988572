import gql from 'graphql-tag';

export const GET_ECDA_REGISTRATION_DOCUMENT_LIST = gql`
  query getECDARequiredRegDocuments($IDECDARegistration: Int!) {
    getRequiredECDARegDocuments(IDECDARegistration: $IDECDARegistration) {
      totalCount
      data {
        ID
        status
        remarks
        reviewedBy
        label
        forStaffOnly
        documentTypeConfig {
          label
          description
          code
        }
        fkEcdaRegistrationChild
        ecdaRegistrationChild {
          fullname
          birthCertificate
        }
        fkEcdaRegistrationParent
        ecdaRegistrationParent {
          fullname
          identificationNo
          ecdaRegistrationParentAttributes {
            data {
              value
            }
          }
        }
        documentTags {
          data {
            ID
            document {
              ID
              filename
              updatedAt
              uRL
              preSignedUrl
              user {
                ID
                firstname
                lastname
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ECDA_REG_DOCUMENT_UPLOAD_URL = gql`
  mutation getECDARegistrationDocumentUploadUrl(
    $IDECDARegistration: Int!
    $file: Upload!
  ) {
    getECDARegistrationDocumentUploadUrl(
      IDECDARegistration: $IDECDARegistration
      file: $file
    ) {
      Url
      Key
      Filename
    }
  }
`;

export const REMOVE_ECDA_REG_DOCUMENT = gql`
  mutation removeECDARegDocument(
    $ecdaRegistrationId: Int!
    $documentId: Int!
    $remarks: String
  ) {
    removeECDARegDocument(
      ecdaRegistrationId: $ecdaRegistrationId
      documentTypeId: $documentId
      remarks: $remarks
    )
  }
`;

export const APPROVE_ECDA_REG_DOCUMENT = gql`
  mutation approveECDARegDocument(
    $ecdaRegistrationId: Int!
    $documentTypeId: Int!
    $remarks: String
  ) {
    approveECDARegDocument(
      ecdaRegistrationId: $ecdaRegistrationId
      documentTypeId: $documentTypeId
      remarks: $remarks
    )
  }
`;

export const REJECT_ECDA_REG_DOCUMENT = gql`
  mutation rejectECDARegDocument(
    $ecdaRegistrationId: Int!
    $documentTypeId: Int!
    $remarks: String
  ) {
    rejectECDARegDocument(
      ecdaRegistrationId: $ecdaRegistrationId
      documentTypeId: $documentTypeId
      remarks: $remarks
    )
  }
`;

export const REVERT_ECDA_REG_DOCUMENT = gql`
  mutation revertECDARegDocument(
    $ecdaRegistrationId: Int!
    $documentTypeId: Int!
    $remarks: String
  ) {
    revertECDARegDocument(
      ecdaRegistrationId: $ecdaRegistrationId
      documentTypeId: $documentTypeId
      remarks: $remarks
    )
  }
`;

export const SKIP_ECDA_REG_DOCUMENT = gql`
  mutation skipECDARegDocument(
    $ecdaRegistrationId: Int!
    $documentTypeId: Int!
    $remarks: String
  ) {
    skipECDARegDocument(
      ecdaRegistrationId: $ecdaRegistrationId
      documentTypeId: $documentTypeId
      remarks: $remarks
    )
  }
`;

export const SUBMIT_ECDA_REG_FORM = gql`
  mutation submitECDARegistrationForm(
    $ecdaRegistrationId: Int!
    $info: ECDARegistrationFormAdditionalInfo
  ) {
    submitECDARegistrationForm(
      ecdaRegistrationId: $ecdaRegistrationId
      info: $info
    )
  }
`;

export const GET_ECDA_REG_TNC_UPLOAD_URL = gql`
  mutation getECDARegistrationTCUploadUrl(
    $IDECDARegistration: Int!
    $file: Upload!
  ) {
    getECDARegistrationTCUploadUrl(
      IDECDARegistration: $IDECDARegistration
      file: $file
    ) {
      Url
      Key
      Filename
    }
  }
`;
